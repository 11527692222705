import { useEffect, useState } from 'react'
import { useAuth } from 'src/lib/auth'
import { navigate, routes } from '@redwoodjs/router'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/@/components/ui/dialog'

import Sidebar from 'src/components/Sidebar/Sidebar'
import Tabbar from 'src/components/Tabbar/Tabbar'
import { Toaster } from '@redwoodjs/web/toast'

const GlobalLayout = ({ children }) => {
  const [developerMode, setDeveloperMode] = useState(false)
  const { isAuthenticated, currentUser, logOut, loading } = useAuth()

  useEffect(() => {
    const dev = localStorage.getItem('FORTUNE_DEVELOPER_MODE')
    setDeveloperMode(dev == 'true' ? true : false)
  }, [])

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate(routes.login())
    }
  }, [isAuthenticated, loading])

  if (loading) {
    return <div>Loading...</div>
  }

  const handleLogout = () => {
    logOut()
    navigate(routes.login())
  }

  if (
    !currentUser?.membership ||
    !Array.isArray(currentUser.membership) ||
    currentUser.membership.length === 0
  ) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Invitation Required</DialogTitle>
          </DialogHeader>
          <p>
            You need to be invited by someone from your organization to access
            this page.
          </p>
          <button
            onClick={handleLogout}
            className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Logout
          </button>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div className="flex h-screen flex-col">
      {developerMode && (
        <div className="fixed flex h-6 w-screen justify-end">
          <div className="mr-12 flex rounded-lg bg-orange-300 px-4 text-center font-bold shadow-sm">
            Developer Mode
          </div>
        </div>
      )}
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <div className="flex flex-grow overflow-hidden">
        <div className="hidden lg:block">
          <Sidebar />
        </div>
        <div className="flex-grow overflow-auto">{children}</div>
      </div>
      <div className="z-50 lg:hidden">
        <Tabbar />
      </div>
    </div>
  )
}

export default GlobalLayout
